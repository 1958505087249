"use client";

import type { Testimonial, Video, Videos, VideosList } from "./types";

export const bigCardContent = [
    {
        title: "Stabilität macht glücklich",
        content:
            "Ein glücklicher Mensch ist für mich jemand, der in seinem Leben steht, an dem Punkt, wo er stehen will. Oder mit dem Punkt, wo er steht, umgehen kann und für sich zufrieden ist und eigentlich auch mit dem zufrieden ist, was er hat.",
        img: "/images/img1.png",
        url: "/blog-post",
    },
    {
        title: "Hochsensibilität: Tipps und Umgang",
        content:
            "Bei Hochsensibilität gibt es verschiedene Tipps, die dir zeigen sollen, wie du dich abgrenzen kannst. Viele sehen sie als eine Gabe an. Es steckt jedoch etwas anderes dahinter. Hochsensible Menschen reagieren auf Reize, die ihr System nur schwer verarbeiten kann.",
        img: "/images/img2.png",
        url: "/blog-post",
    },
    {
        title: "Ängste fordern neue Perspektive",
        content:
            "Ängste lähmen, doch sie zeigen auch auf, wo etwas nicht stimmt. Mit der Stärkung von Energiefeldern ermöglicht Rico Brunner Menschen aus eigener Kraft heraus, ihre Haltung zur Angst zu verändern und Schritt für Schritt eine neue Perspektive zu entwickeln.",
        img: "/images/img3.png",
        url: "/blog-post",
    },
    {
        title: "Entspannt mit Rückschlägen umgehen",
        content:
            "Ein Mensch, der vertrauensvoll durch das Leben geht, der geht mit Rückschlägen entspannter um. Der geht mit Niederlagen entspannter um und weiss, dass nach jeder Niederlage eine Gelegenheit kommt, wieder weiter zu kommen und auch zu gewinnen», sagt Energiefeldmechaniker Rico Brunner",
        img: "/images/img4.png",
    },
];

// biome-ignore lint/suspicious/noExplicitAny: we dont know the type of tracks and button
export const toggleCaption = (mode: string, tracks: any, button: any) => {
    if (mode === "showing") {
        tracks[1].mode = "hidden";
        button.removeClass("sub-button-on");
        button.addClass("sub-button-off");
    } else {
        tracks[1].mode = "showing";
        button.removeClass("sub-button-off");
        button.addClass("sub-button-on");
    }
};

export const videoJsOptions = (dashUrl: string, hlsUrl: string) => {
    return {
        autoplay: true,
        controls: true,
        sources: [
            {
                src: hlsUrl,
                type: "application/x-mpegURL",
            },
        ],
        preload: "auto",
        navigationUI: "hide",
        playbackRates: [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2],
        controlBar: {
            skipButtons: {
                backward: 10,
                forward: 10,
            },
        },
        sm: {
            fullscreen: true,
            aspectRatio: "16:9",
            fill: true,
        },
    };
};

export const setIcon = (active: string | undefined) => {
    switch (active) {
        case "Kostenlos": {
            return "/Icons/TabIcons/likeCyan.svg";
        }
        case "Neu": {
            return "/Icons/TabIcons/plusCyan.svg";
        }
        case "Coming-soon": {
            return "/Icons/TabIcons/clockCyan.svg";
        }
        case "Alle": {
            return "/Icons/TabIcons/tvCyan.svg";
        }
        case "Kostenpflichtig": {
            return "/Icons/TabIcons/paidCyan.svg";
        }
        case "Meine-Masterclass": {
            return "/Icons/TabIcons/userCyan.svg";
        }
        case "Favoriten": {
            return "/Icons/TabIcons/starCyan.svg";
        }
    }
};

export const shuffle = (array: Testimonial[]) => {
    return array.sort(() => Math.random() - 0.5);
};

export const formatDuration = (duration: string | number | undefined) => {
    if (typeof duration === "string") {
        const minutes = `${Math.floor(Number.parseInt(duration) / 60)}:${
            Number.parseInt(duration) % 60 < 10 ? "0" : ""
        }${Number.parseInt(duration) % 60}`;
        return minutes;
    }
    if (typeof duration === "number") {
        const minutes = `${Math.floor(duration / 60)}:${duration % 60 < 10 ? "0" : ""}${
            duration % 60
        }`;
        return minutes;
    }
};
export const formatDurationToMinutes = (duration: string) => {
    const minutes = Math.ceil(Number.parseInt(duration) / 60);
    return minutes;
};
export const formatDurationToHoursAndMinutes = (minutes: number) => {
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return `${hours !== 0 ? `${hours} hrs` : ""}   ${
        remainingMinutes !== 0 ? `${remainingMinutes} min ` : ""
    } `;
};
export const getModuleVideosWithSub = (videos: VideosList, moduleName: string) => {
    const moduleVideos = videos.videos.find(
        (module: Videos) => module.name === moduleName,
    );
    return moduleVideos?.videos.flatMap((video: Video) => {
        const subVideos = video.sub_videos
            ? video.sub_videos.map((subVideo: Video) => subVideo)
            : [];
        return [video, ...subVideos];
    });
};

export const testimonialImg = [
    {
        url: "/images/testimonials/videoTestemonial/Ausgeglichener_Thumbnail_White.jpg",
        name: "Ausgeglichener_Thumbnail_White",
    },
    {
        url: "/images/testimonials/videoTestemonial/Druckumgehen_Thumbnail_White.jpg",
        name: "Druckumgehen_Thumbnail_White",
    },
    {
        url: "/images/testimonials/videoTestemonial/Hamsterrad_Thumbnail_White.jpg",
        name: "Hamsterrad_Thumbnail_White",
    },
    {
        url: "/images/testimonials/videoTestemonial/MeineLebens.jpg",
        name: "MeineLebens",
    },
    {
        url: "/images/testimonials/videoTestemonial/Stabiler_Thumbnail_White.jpg",
        name: "Stabiler_Thumbnail_White",
    },
];
export const testimonialEnImg = [
    {
        url: "/images/testimonials/videoTestemonial/en/Ausgeglichener_Thumbnail_White.jpg",
        name: "Ausgeglichener_Thumbnail_White",
    },
    {
        url: "/images/testimonials/videoTestemonial/en/Druckumgehen_Thumbnail_White.jpg",
        name: "Druckumgehen_Thumbnail_White",
    },
    {
        url: "/images/testimonials/videoTestemonial/en/Hamsterrad_Thumbnail_White.png",
        name: "Hamsterrad_Thumbnail_White",
    },
    {
        url: "/images/testimonials/videoTestemonial/en/MeineLebens.jpg",
        name: "MeineLebens",
    },
    {
        url: "/images/testimonials/videoTestemonial/en/Stabiler_Thumbnail_White.jpg",
        name: "Stabiler_Thumbnail_White",
    },
];

export const buttonsBlogPage = [
    {
        text: "MASTERCLASS",
        url: "/de/masterclass",
        backgroundColor: "bg-none",
        textColor: "text-black",
        border: "border-2 border-black",
        paddings: "px-6 py-3",
    },
    {
        text: "Arbeitsweise",
        url: "/de/arbeitsweise/wie-funktionierts",
        backgroundColor: "bg-none",
        textColor: "text-black",
        border: "border-2 border-black",
        paddings: "px-6 py-3",
    },
    {
        text: "Fernbehhandlung buchen",
        url: "/de/fernbehandlung/jetzt-buchen",
        backgroundColor: "bg-darkPink",
        textColor: "text-white",
        border: "",
        paddings: "lg:px-6 lg:py-3 py-4 px-2",
    },
];

export const newCategories = [
    {
        category: { slug: "ursachen-symptome", name: "Ursachen & Symptome" },
        subCategories: [
            { slug: "angste", name: "Ängste" },
            { slug: "regeneration", name: "Regeneration" },
            { slug: "burnout", name: "Burnout" },
            { slug: "hochsensibilitat", name: "Hochsensibilität" },
            { slug: "depression", name: "Depression" },
            { slug: "traurigkeit", name: "Traurigkeit" },
            { slug: "stress", name: "Stress" },
            { slug: "seelische-verletzungen", name: "Seelische Verletzungen" },
            { slug: "innere-unruhe", name: "Innere Unruhe" },
            { slug: "vermeidungsverhalten", name: "Vermeidungsverhalten" },
            { slug: "unsicherheiten", name: "Unsicherheiten" },
            { slug: "uberforderung", name: "Überforderung" },
            { slug: "emotionale-probleme", name: "Emotionale Probleme" },
            { slug: "panikattacken", name: "Panikattacken" },
            { slug: "trauma", name: "Trauma" },
            { slug: "blockaden", name: "Blockaden" },
        ],
    },
    {
        category: { slug: "arbeit-an-sich-selbst", name: "Arbeit an sich selbst" },
        subCategories: [
            { slug: "selbstheilung", name: "Selbstheilung" },
            { slug: "verhaltensprobleme", name: "Verhaltensprobleme" },
            { slug: "intuition", name: "Intuition" },
            { slug: "emotionale-stabilitat", name: "Emotionale Stabilität" },
            { slug: "umgang-mit-krisen", name: "Umgang mit Krisen" },
            { slug: "widerstandsfahigkeit", name: "Widerstandsfähigkeit" },
            { slug: "aufbau-der-eigenen-kraft", name: "Aufbau der eigenen Kraft" },
            { slug: "seelische-entwicklung", name: "Seelische Entwicklung" },
            { slug: "praxisbeispiele", name: "Praxisbeispiele" },
            { slug: "kundenfragen", name: "Kundenfragen" },
            { slug: "emotionen-lesen", name: "Emotionen lesen" },
        ],
    },
    {
        category: {
            slug: "grundlagen-von-ricos-energiearbeit",
            name: "Grundlagen von Ricos Energiearbeit",
        },
        subCategories: [
            { slug: "energiefeldmechanik", name: "Energiefeldmechanik" },
            {
                slug: "verschiedene-zustande-der-energiefelder",
                name: "Verschiedene Zustände der Energiefelder",
            },
            {
                slug: "energiefeld-instandsetzung",
                name: "Energiefeld-InstandsetzungFernbehandlung",
            },
            { slug: "fernbehandlung", name: "Fernbehandlung" },
            { slug: "forschung", name: "Forschung" },
            { slug: "funktionsstorungen", name: "Funktionsstörungen" },
        ],
    },
];
export const newestCategories = [
    {
        category: { slug: "ursachen-symptome", name: "Ursachen & Symptome" },
        subCategories: [
            { slug: "hochsensibilitat", name: "Hochsensibilität" },
            { slug: "angste", name: "Ängste" },
            { slug: "unsicherheiten", name: "Unsicherheiten" },
            { slug: "trauma", name: "Trauma" },
            { slug: "uberforderung", name: "Überforderung" },
            { slug: "emotionale-probleme", name: "Emotionale Probleme" },
            { slug: "depression", name: "Depression" },
            { slug: "regeneration", name: "Regeneration" },
        ],
    },
    {
        category: { slug: "verhalten-gewohnheiten", name: "Verhalten und Gewohnheiten" },
        subCategories: [
            { slug: "vermeidungsverhalten", name: "Vermeidungsverhalten" },
            { slug: "blockaden", name: "Blockaden" },
            { slug: "unsicherheiten", name: "Unsicherheiten" },
            { slug: "uberforderung", name: "Überforderung" },
        ],
    },
    {
        category: { slug: "stressmanagement", name: "Stressmanagement" },
        subCategories: [
            { slug: "stress", name: "Stress" },
            { slug: "burnout", name: "Burnout" },
            { slug: "innere-unruhe", name: "Innere Unruhe" },
            { slug: "regeneration", name: "Regeneration" },
        ],
    },
    {
        category: { slug: "emotionale-unterstutzung", name: "Emotionale Unterstützung" },
        subCategories: [
            { slug: "emotionale-probleme", name: "Emotionale Probleme" },
            { slug: "seelische-verletzungen", name: "Seelische Verletzungen" },
            { slug: "traurigkeit", name: "Traurigkeit" },
        ],
    },
    {
        category: { slug: "arbeit-an-sich-selbst", name: "Arbeit an sich selbst" },
        subCategories: [
            { slug: "seelische-verletzungen", name: "Seelische Verletzungen" },
            { slug: "blockaden", name: "Blockaden" },
            { slug: "funktionsstorungen", name: "Funktionsstörungen" },
            { slug: "verhaltensprobleme", name: "Verhaltensprobleme" },
            // {slug:"",name:"Fehlentwicklungen"}
            // { slug: "intuition", name: "Degenerative Energiefelder" },
        ],
    },
    {
        category: {
            slug: "grundlagen-energiefeld-therapie",
            name: "Energiefeld-Therapie",
        },
        subCategories: [
            { slug: "energiefeldmechanik", name: "Energiefeldmechanik" },
            {
                slug: "energiefeld-instandsetzung",
                name: "Energiefeld-Instandsetzung Fernbehandlung",
            },
            { slug: "energetische-fernheilung", name: "Energetische Fernheilung" },
            { slug: "forschung", name: "Forschung" },
            { slug: "funktionsstorungen", name: "Funktionsstörungen" },
            {
                slug: "verschiedene-energiefeld-zustande",
                name: "Verschiedene Energiefeld-Zustände",
            },
        ],
    },
];

export const country_list = [
    "Afghanistan",
    "Albania",
    "Algeria",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antigua &amp; Barbuda",
    "Argentina",
    "Armenia",
    "Aruba",
    "Australia",
    "Austria",
    "Azerbaijan",
    "Bahamas",
    "Bahrain",
    "Bangladesh",
    "Barbados",
    "Belarus",
    "Belgium",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivia",
    "Bosnia &amp; Herzegovina",
    "Botswana",
    "Brazil",
    "British Virgin Islands",
    "Brunei",
    "Bulgaria",
    "Burkina Faso",
    "Burundi",
    "Cambodia",
    "Cameroon",
    "Cape Verde",
    "Cayman Islands",
    "Chad",
    "Chile",
    "China",
    "Colombia",
    "Congo",
    "Cook Islands",
    "Costa Rica",
    "Cote D Ivoire",
    "Croatia",
    "Cruise Ship",
    "Cuba",
    "Cyprus",
    "Czech Republic",
    "Denmark",
    "Djibouti",
    "Dominica",
    "Dominican Republic",
    "Ecuador",
    "Egypt",
    "El Salvador",
    "Equatorial Guinea",
    "Estonia",
    "Ethiopia",
    "Falkland Islands",
    "Faroe Islands",
    "Fiji",
    "Finland",
    "France",
    "French Polynesia",
    "French West Indies",
    "Gabon",
    "Gambia",
    "Georgia",
    "Germany",
    "Ghana",
    "Gibraltar",
    "Greece",
    "Greenland",
    "Grenada",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea Bissau",
    "Guyana",
    "Haiti",
    "Honduras",
    "Hong Kong",
    "Hungary",
    "Iceland",
    "India",
    "Indonesia",
    "Iran",
    "Iraq",
    "Ireland",
    "Isle of Man",
    "Israel",
    "Italy",
    "Jamaica",
    "Japan",
    "Jersey",
    "Jordan",
    "Kazakhstan",
    "Kenya",
    "Kuwait",
    "Kyrgyz Republic",
    "Laos",
    "Latvia",
    "Lebanon",
    "Lesotho",
    "Liberia",
    "Libya",
    "Liechtenstein",
    "Lithuania",
    "Luxembourg",
    "Macau",
    "Macedonia",
    "Madagascar",
    "Malawi",
    "Malaysia",
    "Maldives",
    "Mali",
    "Malta",
    "Mauritania",
    "Mauritius",
    "Mexico",
    "Moldova",
    "Monaco",
    "Mongolia",
    "Montenegro",
    "Montserrat",
    "Morocco",
    "Mozambique",
    "Namibia",
    "Nepal",
    "Netherlands",
    "Netherlands Antilles",
    "New Caledonia",
    "New Zealand",
    "Nicaragua",
    "Niger",
    "Nigeria",
    "Norway",
    "Oman",
    "Pakistan",
    "Palestine",
    "Panama",
    "Papua New Guinea",
    "Paraguay",
    "Peru",
    "Philippines",
    "Poland",
    "Portugal",
    "Puerto Rico",
    "Qatar",
    "Reunion",
    "Romania",
    "Russia",
    "Rwanda",
    "Saint Pierre &amp; Miquelon",
    "Samoa",
    "San Marino",
    "Satellite",
    "Saudi Arabia",
    "Senegal",
    "Serbia",
    "Seychelles",
    "Sierra Leone",
    "Singapore",
    "Slovakia",
    "Slovenia",
    "South Africa",
    "South Korea",
    "Spain",
    "Sri Lanka",
    "St Kitts &amp; Nevis",
    "St Lucia",
    "St Vincent",
    "St. Lucia",
    "Sudan",
    "Suriname",
    "Swaziland",
    "Sweden",
    "Switzerland",
    "Syria",
    "Taiwan",
    "Tajikistan",
    "Tanzania",
    "Thailand",
    "Timor L'Este",
    "Togo",
    "Tonga",
    "Trinidad &amp; Tobago",
    "Tunisia",
    "Turkey",
    "Turkmenistan",
    "Turks &amp; Caicos",
    "Uganda",
    "Ukraine",
    "United Arab Emirates",
    "United Kingdom",
    "Uruguay",
    "Uzbekistan",
    "Venezuela",
    "Vietnam",
    "Virgin Islands (US)",
    "Yemen",
    "Zambia",
    "Zimbabwe",
];

export const countries_list_german = [
    "Afghanistan",
    "Ägypten",
    "Åland",
    "Albanien",
    "Algerien",
    "Amerikanisch-Samoa",
    "Amerikanische Jungferninseln",
    "Andorra",
    "Angola",
    "Anguilla",
    "Antarktis",
    "Antigua und Barbuda",
    "Äquatorialguinea",
    "Argentinien",
    "Armenien",
    "Aruba",
    "Aserbaidschan",
    "Äthiopien",
    "Australien",
    "Bahamas",
    "Bahrain",
    "Bangladesch",
    "Barbados",
    "Belarus",
    "Belgien",
    "Belize",
    "Benin",
    "Bermuda",
    "Bhutan",
    "Bolivien",
    "Bonaire",
    "Bosnien und Herzegowina",
    "Botswana",
    "Bouvetinsel",
    "Brasilien",
    "Britische Jungferninseln",
    "Britisches Territorium im Indischen Ozean",
    "Brunei Darussalam",
    "Bulgarien",
    "Burkina Faso",
    "Burundi",
    "Chile",
    "China",
    "Cookinseln",
    "Costa Rica",
    "Elfenbeinküste",
    "Curaçao",
    "Dänemark",
    "Deutschland",
    "Dominica",
    "Dominikanische Republik",
    "Dschibuti",
    "Ecuador",
    "El Salvador",
    "Eritrea",
    "Estland",
    "Falklandinseln",
    "Färöer",
    "Fidschi",
    "Finnland",
    "Frankreich",
    "Französisch-Guayana",
    "Französisch-Polynesien",
    "Französische Süd- und Antarktisgebiete",
    "Gabun",
    "Gambia",
    "Georgien",
    "Ghana",
    "Gibraltar",
    "Grenada",
    "Griechenland",
    "Grönland",
    "Guadeloupe",
    "Guam",
    "Guatemala",
    "Guernsey",
    "Guinea",
    "Guinea-Bissau",
    "Guyana",
    "Haiti",
    "Heard und McDonaldinseln",
    "Honduras",
    "Hongkong",
    "Indien",
    "Indonesien",
    "Insel Man",
    "Irak",
    "Iran",
    "Irland",
    "Island",
    "Israel",
    "Italien",
    "Jamaika",
    "Japan",
    "Jemen",
    "Jersey",
    "Jordanien",
    "Kaimaninseln",
    "Kambodscha",
    "Kamerun",
    "Kanada",
    "Kap Verde",
    "Kasachstan",
    "Katar",
    "Kenia",
    "Kirgisistan",
    "Kiribati",
    "Kokosinseln",
    "Kolumbien",
    "Komoren",
    "Kongo",
    "Nordkorea",
    "Südkorea",
    "Kroatien",
    "Kuba",
    "Kuwait",
    "Laos",
    "Lesotho",
    "Lettland",
    "Libanon",
    "Liberia",
    "Libyen",
    "Liechtenstein",
    "Litauen",
    "Luxemburg",
    "Macao",
    "Madagaskar",
    "Malawi",
    "Malaysia",
    "Malediven",
    "Mali",
    "Malta",
    "Marokko",
    "Marshallinseln",
    "Martinique",
    "Mauretanien",
    "Mauritius",
    "Mayotte",
    "Mexiko",
    "Mikronesien",
    "Moldawien",
    "Monaco",
    "Mongolei",
    "Montenegro",
    "Montserrat",
    "Mosambik",
    "Myanmar",
    "Namibia",
    "Nauru",
    "Nepal",
    "Neukaledonien",
    "Neuseeland",
    "Nicaragua",
    "Niederlande",
    "Niger",
    "Nigeria",
    "Niue",
    "Nordmazedonien",
    "Nördliche Marianen",
    "Norfolkinsel",
    "Norwegen",
    "Oman",
    "Österreich",
    "Osttimor",
    "Pakistan",
    "Staat Palästina",
    "Palau",
    "Panama",
    "Papua-Neuguinea",
    "Paraguay",
    "Peru",
    "Philippinen",
    "Pitcairninseln",
    "Polen",
    "Portugal",
    "Puerto Rico",
    "Taiwan",
    "Republik Kongo",
    "Réunion",
    "Ruanda",
    "Rumänien",
    "Russland",
    "Saint-Barthélemy",
    "Saint-Martin",
    "Salomonen",
    "Sambia",
    "Samoa",
    "San Marino",
    "São Tomé und Príncipe",
    "Saudi-Arabien",
    "Schweden",
    "Schweiz",
    "Senegal",
    "Serbien",
    "Seychellen",
    "Sierra Leone",
    "Simbabwe",
    "Singapur",
    "Sint Maarten",
    "Slowakei",
    "Slowenien",
    "Somalia",
    "Spanien",
    "Sri Lanka",
    "St. Helena",
    "St. Kitts und Nevis",
    "St. Lucia",
    "Saint-Pierre und Miquelon",
    "St. Vincent und die Grenadinen",
    "Südafrika",
    "Sudan",
    "Südgeorgien und die Südlichen Sandwichinseln",
    "Südsudan",
    "Suriname",
    "Svalbard und Jan Mayen",
    "Eswatini",
    "Syrien, Arabische Republik",
    "Tadschikistan",
    "Tansania",
    "Thailand",
    "Togo",
    "Tokelau",
    "Tonga",
    "Trinidad und Tobago",
    "Tschad",
    "Tschechien",
    "Tunesien",
    "Türkei",
    "Turkmenistan",
    "Turks- und Caicosinseln",
    "Tuvalu",
    "Uganda",
    "Ukraine",
    "Ungarn",
    "United States Minor Outlying Islands",
    "Uruguay",
    "Usbekistan",
    "Vanuatu",
    "Vatikanstadt",
    "Venezuela",
    "Vereinigte Arabische Emirate",
    "Vereinigte Staaten von Amerika",
    "Vereinigtes Königreich",
    "Vietnam",
    "Wallis und Futuna",
    "Weihnachtsinsel",
    "Westsahara",
    "Zentralafrikanische Republik",
    "Zypern",
    "Kosovo",
];
